import React from 'react';

export function App() {
  return (
    <React.Fragment>
      <h1>ahanu<span>AI</span></h1>
      <footer>Seattle <span>{'//'}</span> Brooklyn <span>{'//'}</span> Des Moines</footer>
    </React.Fragment>
  );
}
