import firebase from 'firebase/app';
import 'firebase/analytics';
import React from 'react';
import ReactDom from 'react-dom';
import {App} from './components/App';
import '../node_modules/modern-normalize/modern-normalize.css';
import './style.scss';

const firebaseConfig = {
  apiKey: "AIzaSyB25mDrU9oygA1xBSBw5WfkdhNsnSgQ1mg",
  authDomain: "ahanu-site.firebaseapp.com",
  projectId: "ahanu-site",
  storageBucket: "ahanu-site.appspot.com",
  messagingSenderId: "569752706693",
  appId: "1:569752706693:web:b4f315fdbb42a4eb1a13ec",
  measurementId: "G-32XJMKGDYY"
};;

firebase.initializeApp(firebaseConfig);

const appMount = document.getElementById('app-mount');

ReactDom.render(<App/>, appMount);
